<template>
  <v-tabs
    v-model="activeTab"
    vertical
    color="success"
    class="px-5 pb-2 text-align: center"
    density="compact"
  >
    <!-- Tab Names  -->
    <v-tab>
      Insurance
    </v-tab>
    <v-tab>
      Comments
    </v-tab>

    <v-tab-item eager>
      <v-card
        v-if="!patientInsuranceData.insurance_type_title"
        class="col-sm-12 mb-3"
      >
        <div>
          <h3
            class="col-sm-12"
          >
            No insurance data found!
          </h3>
        </div>
      </v-card>
      <v-card
        v-else
        class="col-sm-12 mb-3"
      >
        <div
          class="col-sm-12"
        >
          <h3
            class="col-sm-12 font-weight-regular"
          >
            Patient Insurance Type: <b>{{ patientInsuranceData.insurance_type_title }}</b>
          </h3>
          <h3
            class="col-sm-8 font-weight-regular"
          >
            Authorization Obtained
            <v-icon
              class="pl-1"
            >
              {{ patientInsuranceData.is_auth_obtained ? icon.checkBoxTicked : icon.checkBoxBlank }}
            </v-icon>
          </h3>
          <h3
            v-if="patientInsuranceData.is_auth_obtained"
            class="col-sm-12 font-weight-regular"
          >
            Authorization Date: <b>{{ $date(patientInsuranceData.date_auth_obtained).format('MM/DD/YY h:mm A') }}</b>
          </h3>
        </div>
      </v-card>
    </v-tab-item>

    <!-- Comments  -->
    <v-tab-item
      eager
      class="col-sm-12"
    >
      <Comments
        type="insuranceAuth"
        :patient-id="patientId"
        :encounter-id="encounter.id"
        :comment-adds="commentAdds"
        :items-per-page="5"
      >
      </Comments>
    </v-tab-item>

    <!-- History  REMOVED, as per Billing-->
    <!-- <v-tab-item eager>
        <v-data-table
          :items="mockDataHistory"
          item-key="id"
          :headers="headersHistory"
          :page.sync="paginationHistory.page"
          :items-per-page="paginationHistory.itemsPerPage"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          hide-default-footer
          class="has-pagination col-sm-12 pa-2"
          @page-count="paginationHistory.pageCount = $event"
        >
        </v-data-table>

        <pagination
          :page.sync="paginationHistory.page"
          :items-per-page.sync="paginationHistory.itemsPerPage"
          :page-count="paginationHistory.pageCount"
          class="col-sm-10"
        ></pagination>
      </v-tab-item>
    </v-tabs> -->
  </v-tabs>
</template>

<script>
import {
  mdiCheckboxBlankOutline, mdiCloseCircle, mdiSend, mdiCheckboxOutline,
} from '@mdi/js'
import Comments from '@/components/features/Comments.vue'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'

const initialState = {
  by: 'created',
  desc: true,
}
export default {
  components: { Comments },
  props: {
    encounter: {
      type: Object,
      default: () => {},
    },
    patientId: {
      type: String,
      default: null,
    },
    commentAdds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // DEBUG
      debug: false,

      // Base data
      loading: false,

      // v-models
      comment: {
        patient_id: this.patientId,
        created_by_user_id: this.$authUser.user().id,
        comment: null,
      },

      // Headers
      headersComments: [
        {
          text: 'Is Read?', value: 'is_read', sortable: true,
        },
        {
          text: 'Date', value: 'created', sortable: true,
        },
        {
          text: 'From user', value: 'created_by_user_id', sortable: true,
        },
        {
          text: 'Comment', value: 'comment', sortable: false,
        },
      ],

      // REMOVED due to Billing request
      // headersHistory: [
      //  {
      //    text: 'Date', value: 'date', sortable: true,
      //  },
      //  {
      //    text: 'User', value: 'user', sortable: true,
      //  },
      //  {
      //    text: 'Change', value: 'change', sortable: false,
      //  },
      // ],

      paginationComments: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },

      // REMOVED due to Billing request
      // paginationHistory: {
      //   page: 1,
      //   pageCount: 0,
      //   itemsPerPage: 5,
      // },

      sort: {
        by: 'created',
        desc: true,
      },
      confirmComment: false,

      icon: {
        comments: mdiSend,
        close: mdiCloseCircle,
        checkBoxTicked: mdiCheckboxOutline,
        checkBoxBlank: mdiCheckboxBlankOutline,
      },
      section: {
        ...this.$lodash.cloneDeep(initialState),
        ...this.value,
      },
      modalState: false,
      activeTab: 0,
    }
  },
  computed: {
    ...mapState('comments', { comments: 'items' }),
    ...mapFields(['online']),

    patientData() {
      return this.$store.getters['patients/getById'](this.patientId)
    },
    patientInsuranceData() {
      const data = {
        insurance_type_title: null,
        is_auth_obtained: null,
        date_auth_obtained: null,
      }

      if (!this.patientData.insurance_type_title) {
        return data
      }

      return {
        insurance_type_title: this.patientData.insurance_type_title,
        is_auth_obtained: this.patientData.is_auth_obtained,
        date_auth_obtained: this.patientData.date_auth_obtained,
      }
    },
  },
  watch: {
    authorization_value() {
      this.$emit('update:is-auth-obtained', this.authorization_value)
    },
    authorization_date() {
      this.$emit('update:date-auth-obtained', this.authorization_date)
    },
  },
  mounted() {
    if (this.debug === true) {
      console.log(this.patientInsuranceData)
    }
  },
  methods: {
    // Handlers
    authValueChanged(event) {
      this.authorization_value = event
    },
    authDateChanged(event) {
      if (this.$custom.isDateValid(event.target.value)) {
        this.visit_date = this.$date(event.target.value).format('YYYY-MM-DD')
      }
    },
  },
}
</script>

<style lang="scss">
</style>
